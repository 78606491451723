import React from 'react'

function Loader() {
  return (
    <div className="flex justify-center items-center">
    <div className="loader"></div>
    </div>
  )
}

export default Loader